import React from 'react';

function Footer(){
    return(
        <div className="Footer">
            ~ Samuel Chin © 2020 ~
        </div>
    )
}

export default Footer;