import React from 'react';


function FrontPage(){
    return(
        <div className="front-page-main">
            <img className="front-page-img" src="https://cdn.discordapp.com/attachments/718455188100350035/753550132183695470/Personal_Project_Welcome_Maido_2.1.png"/>
        </div>
    )
}


export default FrontPage;